import { GET_BACKER_SUCCESS } from "../actions/consts"

const initialState = {
  data: {},
  backer: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BACKER_SUCCESS:
      return { ...state, backer: payload.data }

    default:
      return state
  }
}

export const getProjects = state => state.projects
export const getProjectsData = state => getProjects(state).data
export const getProjectsBacker = state => getProjects(state).backer
