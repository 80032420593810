import { GET_BACKER, GET_BACKER_SUCCESS, GET_BACKER_FAILED } from "./consts"

export const getBacker = id => ({
  type: GET_BACKER,
  payload: { id },
})

export const getBackerSuccess = data => ({
  type: GET_BACKER_SUCCESS,
  payload: { data },
})

export const getBackerFailed = error => ({
  type: GET_BACKER_FAILED,
  payload: { error },
})
