import { errorHandler } from "./utils"

export default client => {
  const getBacker = id =>
    client
      .get(`backervoice/backer/${id}`)
      .then(({ data }) => data)
      .catch(errorHandler)

  return {
    getBacker,
  }
}
